import React from 'react'
import cx from 'classnames'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ products }) => {
  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        {products.products &&
          products.products.map(product => (
            <div className={styles.item}>
              <p className={styles.smallTitle}>{product.smallTitle}</p>
              <p className={styles.title}>{product.title}</p>
              <p className={styles.name} dangerouslySetInnerHTML={{ __html: product.name }} />
              <img
                className={styles.image}
                {...srcSetProps(sanityImageUrl(product.image))}
                alt={product.image?.caption}
              />
              <ul className={styles.benefits}>
                {product.benefits &&
                  product.benefits.map((item, index) => (
                    <li className={styles.benefit} key={item._key}>
                      <p className={styles.benefitValue}>{item.value}</p>
                      <p className={styles.benefitText}>{item.text}</p>
                    </li>
                  ))}
              </ul>

              {product.isDisabled ? (
                <a className={cx(styles.button, styles.buttonDisabled)}>{product.buttonText}</a>
              ) : (
                <a href={product.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
                  {product.buttonText}
                </a>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
