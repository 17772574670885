import React from 'react'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ foodAndDrugAdministration }) => {
  return (
    <div className={styles.wrap}>
      <h2 className={styles.title}>{prepareParagraph(foodAndDrugAdministration.text)}</h2>
    </div>
  )
}
