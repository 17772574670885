import React from 'react'
import styles from './style.module.sass'

export default ({ howItWorks }) => {
  return (
    <div className={styles.howItWorks}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{howItWorks.title}</h2>
          <p className={styles.description}>{howItWorks.description}</p>
        </div>
        <div className={styles.bottom}>
          {howItWorks.benefits &&
            howItWorks.benefits.map(item => (
              <div className={styles.item} key={item._key}>
                <p className={styles.value}>{item.title}</p>
                <p className={styles.text}>{item.text}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
