import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import Header from '../components/WeightLossPage/Header'
import Info from '../components/WeightLossPage/Info'
import Table from '../components/WeightLossPage/Table'
import HowItWorks from '../components/WeightLossPage/HowItWorks'
import Products from '../components/WeightLossPage/Products'
import Faq from '../components/WeightLossPage/Faq'
import References from '../components/WeightLossPage/References'
import FoodAndDrugAdministration from '../components/WeightLossPage/FoodAndDrugAdministration'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      <Header header={data.header} />
      <Info products={data.productsTop} />
      <Table table={data.table} />
      <HowItWorks howItWorks={data.howItworks} />
      <Products products={data.products} />
      <Faq faq={data.faq} />
      <References references={data.references} />
      <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />
    </Layout>
  )
}
