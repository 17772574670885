import React from 'react'
import styles from './style.module.sass'

export default ({ table }) => {
  if (!table || table.isHide) return null

  return (
    <div className={styles.table}>
      <div className={styles.wrap}>
        <div className={styles.row}>
          <div className={styles.col}></div>
          <div className={styles.col}>
            <p className={styles.titleSmall}>{table.title_column_1_sm}</p>
            <p className={styles.titleBig}>{table.title_column_1}</p>
          </div>
          <div className={styles.col}>
            <p className={styles.titleSmall}>{table.title_column_2_sm}</p>
            <p className={styles.titleBig}>{table.title_column_2}</p>
          </div>
          {table.title_column_3 && (
            <div className={styles.col}>
              <p className={styles.titleSmall}>{table.title_column_3_sm}</p>
              <p className={styles.titleBig}>{table.title_column_3}</p>
            </div>
          )}
        </div>

        {table.tableRows &&
          table.tableRows.map(item => (
            <div className={styles.row}>
              <div className={styles.col}>
                <p className={styles.title}>{item.title}</p>
                <p className={styles.info}>{item.info}</p>
              </div>
              <div className={styles.col}>
                {item.column_1_title && <p className={styles.title}>{item.column_1_title}</p>}
                <p className={styles.body} dangerouslySetInnerHTML={{ __html: item.column_1_body }} />
              </div>
              <div className={styles.col}>
                {item.column_1_title && <p className={styles.title}>{item.column_2_title}</p>}
                <p className={styles.body} dangerouslySetInnerHTML={{ __html: item.column_2_body }} />
              </div>
              {table.title_column_3 && (
                <div className={styles.col}>
                  {item.column_1_title && <p className={styles.title}>{item.column_3_title}</p>}
                  <p className={styles.body} dangerouslySetInnerHTML={{ __html: item.column_3_body }} />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
